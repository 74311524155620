import debounceLodash from 'lodash/debounce';
import includesLodash from 'lodash/includes';
import capitalizeLodash from 'lodash/capitalize';
export default {
  methods: {
    debounce(func, wait, options) {
      return debounceLodash(func, wait, options);
    },
    includes(collection, value, fromIndex, guard) {
      return includesLodash(collection, value, fromIndex, guard);
    },
    capitalize(string) {
      return capitalizeLodash(string);
    },
  },
};
